<template>
  <div id="success">
    <Header></Header>
    <div class="breadcrumb-wrap bg-f br-1 h-inner" style="height: 150px">
      <div class="overlay bg-black op-9"></div>
    </div>
    <section class="about-wrap style2 ptb-100">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-12 text-center">
            <div class="about-content mt-5">
              <div class="content-title style2 mt-5">
                <h2 class="mt-5">Order Placed Successfully</h2>
              </div>
            </div>
          </div>
          <div class="col-lg-12 mt-3">
            <div class="successbg text-center">
              <img :src="success" alt="Image" />
            </div>
          </div>
          <div class="col-md-12 mt-30 text-center">
            <router-link to="/myorders" target="_blank" class="btn style1"
              >Go to Order Page <i class="flaticon-right-arrow-2"></i
            ></router-link>
          </div>
        </div>
      </div>
    </section>

    <Footer></Footer>
  </div>
</template>
<script>
import Header from "@/views/layouts/Header.vue";
import Footer from "@/views/layouts/Footer.vue";
import { placeOrder, cloverApiGetPayment } from "@/store/api";
import { getLocalStorage } from "@/store/service";

export default {
  name: "success",
  data() {
    return {
      success: require("@/assets/img/success.png"),
      showSuccess: 0,
      interval: "",
      paymentId: this.$route.params.paymentId,
      security: this.$route.params.security,
    };
  },
  components: {
    Header,
    Footer,
  },
  mounted() {
    if (
      getLocalStorage("submitOrder") &&
      getLocalStorage("submitOrder").method === "Clover" &&
      getLocalStorage("securityToken").id === this.security
    ) {
      cloverApiGetPayment(this.paymentId)
        .then((res) => {
          console.log(res.data);
          const response = res.data;
          if (response.res.status === "succeeded") {
            this.placeOrder();
          } else {
            this.$router.push("/cancel");
          }
        })
        .catch((err) => {
          console.log(err);
          this.$router.push("/menu");
        });
    } else {
      this.$router.push("/menu");
    }
  },
  methods: {
    placeOrder() {
      placeOrder(getLocalStorage("submitOrder")).then((res) => {
        if (res.data.success === true) {
          localStorage.removeItem("cart");
          localStorage.removeItem("submitOrder");
          this.showSuccess = 1;
        }
        this.$toast.success("Order place successfully");
        clearInterval(this.interval);
        // this.interval = setInterval(() => {
        //   this.$router.push('/myorder')
        // }, 10000)
      });
    },
  },
};
</script>
<style scoped>
.breadcrumb-wrap {
  position: relative;
  height: 100%;
  padding: 71px 0 100px;
  overflow: hidden;
}
</style>
